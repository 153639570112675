import React from "react";

export const features = [
  {
    name: "No middle man",
    description:
      "Nobody holds your funds at any time. Payments performed with DeFi, peer to peer.",
    icon: require("line-awesome/svg/handshake-solid.svg"),
  },
  {
    name: "Accept any token",
    description: "Accept 200+ popular tokens as payment. DeFi based instant token conversion to stablecoin.",
    icon: require("line-awesome/svg/bitcoin.svg"),
  },
  {
    name: "Simple to use",
    description:
      "Define your wallet addresses and recieve monew any additional action.",
    icon: require("line-awesome/svg/feather-solid.svg"),
  },
  // {
  //   name: "Reduce fees",
  //   description:
  //     "Define your wallet addresses and recieve monew any additional action.",
  //   icon: require("line-awesome/svg/feather-solid.svg"),
  // },
  {
    name: "Easy Withdraw",
    description:
      "Withdraw your money from your wallet to bank account or card using crypto exchange.",
    icon: require("line-awesome/svg/credit-card-solid.svg"),
  },
]

export const Features = () => (
  <div className="bg-gradient-to-r from-sky-800 to-blue-700">
    <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
      {/* <h2 className="text-3xl font-extrabold text-white tracking-tight">
        More features and endless possibilities
      </h2>
      <p className="mt-4 max-w-3xl text-lg text-sky-200">
        Some features in development now, and you can suggest your own features
        or help with feedback.
      </p> */}
      <div className="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        {features.map(feature => (
          <div key={feature.name}>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                <feature.icon className="h-8 w-8 text-white fill-white" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">{feature.name}</h3>
              <p className="mt-2 text-base text-sky-200">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
