import * as React from "react"
import { Index } from "../components/Index"
import { Layout } from "../components/Layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="WP Crypto Tools" />
    <Index />
  </Layout>
)

export default IndexPage
